import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'


export const query = graphql`
  query {
    bannerImage: file(relativePath: { eq: "pictures/haus.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default function Contact({ data }) {
  return (
    <Layout title="Kontakt" backgroundImage={data.bannerImage.childImageSharp.fluid}>
      <div class="flex flex-col justify-center items-start space-y-5">
        <p class="self-start text-4xl font-light">Kontakt</p>
        <p>
          Sie haben Interesse an unserer Ferienwohnung? Dann rufen Sie einfach an oder schreiben Sie eine E-Mail, damit
          wir die Wohnung für Sie reservieren können. Natürlich können Sie auch ganz unverbindlich die Verfügbarkeit
          erfragen.
        </p>
        <p>
          Wir freuen uns auf Sie!
        </p>
        <div class="w-full flex flex-row items-center space-x-5">
          <svg class="w-10 h-10 fill-current text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
          </svg>
          <a href="tel:+492485911100" class="font-mono">+49 2485 911100</a>
        </div>
        <div class="w-full flex flex-row items-center space-x-5">
          <svg class="w-10 h-10 fill-current text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
          </svg>
          <a href="tel:+4915140171446" class="font-mono">+49 151 40171446</a>
        </div>
        <div class="w-full flex flex-row items-center space-x-5">
          <svg class="w-10 h-10 fill-current text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
            <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
          </svg>
          <a href="mailto:info@ferienwohnung-mediterran.de" class="font-mono">info@ferienwohnung-mediterran.de</a>
        </div>
      </div>
    </Layout>
  )
}
